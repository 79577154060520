define("discourse/plugins/discourse-circles/discourse/components/circles/navigation-combo-box", ["exports", "@ember/application", "@ember/object", "discourse/lib/ajax-error", "discourse-i18n", "select-kit/components/combo-box", "select-kit/components/select-kit"], function (_exports, _application, _object, _ajaxError, _discourseI18n, _comboBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let cache = {};
  const NavigationComboBox = dt7948.c(class NavigationComboBox extends _comboBox.default {
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      cache = {};
    }
    async search(filter) {
      const filterType = this.suggestedType;
      const cacheKey = `${filterType}-${filter}`;
      if (this.get("pendingSearch") === cacheKey) {
        return;
      }
      this.set("pendingSearch", cacheKey);

      // check the cache first
      if (cache[cacheKey]) {
        return cache[cacheKey];
      }

      // if no cache was found, perform the search on the backend
      const data = {
        filter,
        ...(!filter && {
          type: filterType
        })
      };
      const store = (0, _application.getOwner)(this).lookup("service:store");
      try {
        const response = await store.findAll("circle", data);
        const values = response?.content.map(circle => this.defaultItem(circle.slug_path_with_id, circle.name));
        if (!filter) {
          values.unshift(this.defaultItem(null, (0, _discourseI18n.i18n)("discourse_circles.index.all.title")));
        }
        cache[cacheKey] = values;
        return values;
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      } finally {
        this.set("pendingSearch", undefined);
      }
    }
    _defaultItem(circle) {
      return {
        name: circle.name,
        slug_path_with_id: circle.slug_path_with_id
      };
    }
    onChange(slug_path_with_id) {
      const router = (0, _application.getOwner)(this).lookup("service:router");
      if (slug_path_with_id) {
        router.transitionTo("circle", slug_path_with_id);
      } else {
        // navigate to the list page
        router.transitionTo("circles");
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
  }, [(0, _selectKit.selectKitOptions)({
    filterable: true,
    fullWidthOnMobile: true,
    clearable: false,
    autoInsertNoneItem: false,
    valueProperty: "slug_path_with_id"
  })]);
  var _default = _exports.default = NavigationComboBox;
});