define("discourse/plugins/discourse-circles/discourse/components/circles/notifications-button", ["exports", "@ember-decorators/component", "discourse-i18n", "select-kit/components/category-notifications-button", "select-kit/components/select-kit"], function (_exports, _component, _discourseI18n, _categoryNotificationsButton, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NotificationsButton = dt7948.c(class NotificationsButton extends _categoryNotificationsButton.default {}, [(0, _component.classNames)("circles-notifications-button"), (0, _selectKit.selectKitOptions)({
    i18nPrefix: "discourse_circles.circle.notifications",
    headerAriaLabel: (0, _discourseI18n.i18n)("discourse_circles.circle.notifications.title")
  }), (0, _selectKit.pluginApiIdentifiers)("circles-notifications-button")]);
  var _default = _exports.default = NotificationsButton;
});