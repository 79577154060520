define("discourse/plugins/discourse-circles/discourse/routes/circles-index", ["exports", "@ember/service", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _service, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesIndexRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "circlesService", [(0, _service.service)("circles")]))();
    #circlesService = (() => (dt7948.i(this, "circlesService"), void 0))();
    queryParams = {
      filter: {
        refreshModel: true,
        replace: true
      },
      type: {
        refreshModel: true
      },
      ...Object.fromEntries(this.circlesService.customFilters.map(filter => [filter.queryParameter, {
        refreshModel: true
      }]))
    };
    titleToken() {
      return (0, _discourseI18n.i18n)("discourse_circles.index.title_token");
    }
    model(params) {
      return params;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.loadCircles(model);
    }
  }
  _exports.default = CirclesIndexRoute;
});