define("discourse/plugins/discourse-circles/discourse/components/circles/form-fields/custom/base", ["exports", "discourse-i18n", "discourse/plugins/discourse-circles/discourse/components/circles/form-fields/base"], function (_exports, _discourseI18n, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomFieldBase extends _base.default {
    displayDescriptionByDefault = true;
    get shouldDisplayTitle() {
      return super.shouldDisplayTitle && this.args.field.displayName;
    }
    get title() {
      if (!this.shouldDisplayTitle) {
        return null;
      }
      return this.args.title || (0, _discourseI18n.i18n)(this.titleI18n, {
        translatedFallback: this.args.field.displayName
      });
    }
    get shouldDisplayDescription() {
      return super.shouldDisplayDescription && this.args.field.description;
    }
    get description() {
      if (!this.shouldDisplayDescription) {
        return null;
      }
      return this.args.description || (0, _discourseI18n.i18n)(this.descriptionI18n, {
        translatedFallback: this.args.field.description
      });
    }
  }
  _exports.default = CustomFieldBase;
});