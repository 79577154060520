define("discourse/plugins/discourse-circles/discourse/controllers/user-circles", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse-i18n"], function (_exports, _controller, _object, _service, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserCirclesIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    #availableTypes = ["member", "staff"];
    get types() {
      const isSelf = this.currentUser?.username === this.model.username;
      return this.#availableTypes.map(type => {
        const name = isSelf ? type : type + "-else";
        return {
          id: type,
          name,
          title: (0, _discourseI18n.i18n)(`discourse_circles.index.user.types.${name}.title`),
          route: `user.circles.type`,
          model: type
        };
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "types", [(0, _object.computed)("#availableTypes", "currentUser", "model.username")]))();
  }
  _exports.default = UserCirclesIndexController;
});