define("discourse/plugins/discourse-circles/discourse/routes/circle-members", ["exports", "@ember/object", "@ember/service", "discourse/components/modal/create-invite", "discourse/routes/group-index", "discourse-i18n", "discourse/plugins/discourse-circles/discourse/components/modal/add-members"], function (_exports, _object, _service, _createInvite, _groupIndex, _discourseI18n, _addMembers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleMembersRoute extends _groupIndex.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    titleToken() {
      return (0, _discourseI18n.i18n)("discourse_circles.circle.members.title_token", {
        circle_name: this.modelFor("circle").name
      });
    }
    model(params) {
      this._params = params; // from GroupIndexRoute#model

      return this.group;
    }
    get mode() {
      return "members";
    }
    get circle() {
      return this.modelFor("circle");
    }
    get group() {
      return this.circle?.member_group;
    }
    showAddMembersModal() {
      this.modal.show(_addMembers.default, {
        model: {
          circle: this.circle,
          group: this.group,
          mode: this.mode,
          reloadMembers: () => {
            this.controller.reloadMembers(true);
          }
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "showAddMembersModal", [_object.action]))();
    showInviteModal() {
      this.modal.show(_createInvite.default, {
        modal: {
          groupIds: [this.group.id]
        }
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "showInviteModal", [_object.action]))();
  }
  _exports.default = CircleMembersRoute;
});