define("discourse/plugins/discourse-circles/discourse/components/circles/members-dropdown-bulk-actions", ["exports", "@ember/object", "@ember-decorators/component", "discourse-i18n", "select-kit/components/dropdown-select-box", "select-kit/components/select-kit"], function (_exports, _object, _component, _discourseI18n, _dropdownSelectBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MembersDropdownBulkActions = dt7948.c(class MembersDropdownBulkActions extends _dropdownSelectBox.default {
    get content() {
      return [{
        id: "removeMembers",
        name: (0, _discourseI18n.i18n)("discourse_circles.circle.members.remove_members"),
        description: (0, _discourseI18n.i18n)("discourse_circles.circle.members.remove_members_description"),
        icon: "user-xmark"
      }];
    }
    static #_ = (() => dt7948.n(this.prototype, "content", [(0, _object.computed)("bulkSelection.[]")]))();
  }, [(0, _component.classNames)("circles-members-dropdown-bulk-actions"), (0, _selectKit.selectKitOptions)({
    icon: "wrench",
    showFullTitle: false
  }), (0, _selectKit.pluginApiIdentifiers)("circles-members-dropdown-bulk-actions")]);
  var _default = _exports.default = MembersDropdownBulkActions;
});