define("discourse/plugins/discourse-circles/discourse/components/circles/search-fields/text", ["exports", "@ember/component", "@ember/modifier", "@ember/utils", "discourse/lib/debounce", "discourse/lib/decorators", "discourse/lib/environment", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/base", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/wire-frame", "@ember/template-factory"], function (_exports, _component, _modifier, _utils, _debounce, _decorators, _environment, _base, _wireFrame, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchFieldText extends _base.default {
    onValueChanged(event) {
      (0, _debounce.default)(this, this.#debouncedValue, event.target.value, _environment.INPUT_DELAY);
    }
    static #_ = (() => dt7948.n(this.prototype, "onValueChanged", [_decorators.bind]))();
    #debouncedValue(value) {
      this.args.controller.set(this.args.filter.queryParameter, (0, _utils.isEmpty)(value) ? null : value);
    }
    static #_2 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <SearchFieldWireFrame
          ...attributes
          @controller={{@controller}}
          @filter={{@filter}}
          @value={{@value}}
        >
          <Input
            placeholder={{this.placeholder}}
            class="no-blur"
            @value={{readonly @value}}
            {{on "input" this.onValueChanged}}
          />
        </SearchFieldWireFrame>
      
    */
    {
      "id": "uhg38whI",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[17,1]],[[\"@controller\",\"@filter\",\"@value\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[32,1],[[16,\"placeholder\",[30,0,[\"placeholder\"]]],[24,0,\"no-blur\"],[4,[32,2],[\"input\",[30,0,[\"onValueChanged\"]]],null]],[[\"@value\"],[[28,[31,0],[[30,4]],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"&attrs\",\"@controller\",\"@filter\",\"@value\"],false,[\"readonly\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/search-fields/text.js",
      "scope": () => [_wireFrame.default, _component.Input, _modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SearchFieldText;
});