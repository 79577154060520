define("discourse/plugins/discourse-circles/discourse/components/circles/members-dropdown-actions", ["exports", "@ember/object", "@ember/utils", "@ember-decorators/component", "discourse-i18n", "select-kit/components/dropdown-select-box", "select-kit/components/select-kit"], function (_exports, _object, _utils, _component, _discourseI18n, _dropdownSelectBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MembersDropdownActions = dt7948.c(class MembersDropdownActions extends _dropdownSelectBox.default {
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.set("selectKit.isHidden", (0, _utils.isEmpty)(this.content));
    }
    get content() {
      return [{
        id: "removeMember",
        name: (0, _discourseI18n.i18n)("discourse_circles.circle.members.remove_member"),
        description: (0, _discourseI18n.i18n)("discourse_circles.circle.members.remove_member_description", {
          username: this.get("member.username")
        }),
        icon: "user-xmark",
        disabled: this.get("member.owner")
      }].filter(item => !item.disabled);
    }
    static #_ = (() => dt7948.n(this.prototype, "content", [_object.computed]))();
  }, [(0, _component.classNames)("circles-members-dropdown-actions"), (0, _selectKit.selectKitOptions)({
    icon: "wrench",
    showFullTitle: false
  }), (0, _selectKit.pluginApiIdentifiers)("circles-members-dropdown-actions")]);
  var _default = _exports.default = MembersDropdownActions;
});