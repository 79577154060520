define("discourse/plugins/discourse-circles/discourse/components/circles/membership-post-request", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/routing", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/discourse-circles/discourse/models/circle", "discourse/plugins/discourse-circles/discourse/components/modal/membership-reason", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _routing, _service, _dButton, _ajaxError, _discourseI18n, _circle, _membershipReason, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesMembershipPostRequest extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "handled", [_tracking.tracked], function () {
      return false;
    }))();
    #handled = (() => (dt7948.i(this, "handled"), void 0))();
    async acceptRequest() {
      try {
        await _circle.default.handleMembershipRequest(this.args.circleCategoryId, {
          user_id: this.args.userId,
          accept: true
        });
        this.handled = true;
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "acceptRequest", [_object.action]))();
    async denyRequest() {
      const performDeny = async reason => {
        try {
          await _circle.default.handleMembershipRequest(this.args.circleCategoryId, {
            user_id: this.args.userId,
            accept: false,
            reason
          });
          this.handled = true;
        } catch (e) {
          (0, _ajaxError.popupAjaxError)(e);
        }
      };
      if (this.siteSettings.discourse_circles_ask_reason_when_denying_request) {
        const circle = await this.store.find("circle", this.args.circleCategoryId);
        this.modal.show(_membershipReason.default, {
          model: {
            circle,
            // because controllers are singletons. ensures that the reason will
            // be empty
            reason: "",
            mode: "deny",
            onSubmit: performDeny
          }
        });
      } else {
        return await performDeny();
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "denyRequest", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#unless this.handled}}
          <div class="circles-membership-request-handle-bar">
            <DButton
              @action={{this.acceptRequest}}
              @actionParam={{@post.username}}
              @label="discourse_circles.circle.requests.accept"
              class="btn-primary"
            />
            <DButton
              @action={{this.denyRequest}}
              @actionParam={{@post.username}}
              @label="discourse_circles.circle.requests.deny"
              class="btn-danger"
            />
            <LinkTo
              @route="circle.requests"
              @model={{@circleCategoryId}}
              class="btn btn-default"
            >
              {{i18n "discourse_circles.circle.membership_request.handle"}}
            </LinkTo>
          </div>
        {{/unless}}
      
    */
    {
      "id": "//hDirpO",
      "block": "[[[1,\"\\n\"],[41,[51,[30,0,[\"handled\"]]],[[[1,\"      \"],[10,0],[14,0,\"circles-membership-request-handle-bar\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"btn-primary\"]],[[\"@action\",\"@actionParam\",\"@label\"],[[30,0,[\"acceptRequest\"]],[30,1,[\"username\"]],\"discourse_circles.circle.requests.accept\"]],null],[1,\"\\n        \"],[8,[32,0],[[24,0,\"btn-danger\"]],[[\"@action\",\"@actionParam\",\"@label\"],[[30,0,[\"denyRequest\"]],[30,1,[\"username\"]],\"discourse_circles.circle.requests.deny\"]],null],[1,\"\\n        \"],[8,[32,1],[[24,0,\"btn btn-default\"]],[[\"@route\",\"@model\"],[\"circle.requests\",[30,2]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[32,2],[\"discourse_circles.circle.membership_request.handle\"],null]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@post\",\"@circleCategoryId\"],false,[\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/membership-post-request.js",
      "scope": () => [_dButton.default, _routing.LinkTo, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CirclesMembershipPostRequest;
});