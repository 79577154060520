define("discourse/plugins/discourse-circles/discourse/routes/circle-about", ["exports", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleAboutRoute extends _discourse.default {
    showFooter = true;
    titleToken() {
      return (0, _discourseI18n.i18n)("discourse_circles.circle.about.title_token", {
        circle_name: this.modelFor("circle").name
      });
    }
    model() {
      return this.modelFor("circle");
    }
  }
  _exports.default = CircleAboutRoute;
});