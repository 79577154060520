define("discourse/plugins/discourse-circles/discourse/models/circle-custom-field", ["exports", "@glimmer/tracking", "discourse-i18n", "discourse/plugins/discourse-circles/discourse/lib/model-attribute", "discourse/plugins/discourse-circles/discourse/models/attributtes"], function (_exports, _tracking, _discourseI18n, _modelAttribute, _attributtes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleCustomField extends _attributtes.default {
    static #fieldTypes;
    static #attributes;
    static attributes() {
      if (!this.#attributes) {
        this.#attributes = Object.freeze([new _modelAttribute.default("name"), new _modelAttribute.default("description"), new _modelAttribute.default("field_type", {
          defaultValue: "text"
        }), new _modelAttribute.default("options"), new _modelAttribute.default("required", {
          defaultValue: true,
          flag: true
        }), new _modelAttribute.default("editable", {
          defaultValue: false,
          flag: true
        }), new _modelAttribute.default("searchable", {
          defaultValue: false,
          flag: true
        }), new _modelAttribute.default("position"), new _modelAttribute.default("display_on_about_page", {
          defaultValue: true,
          flag: true
        }), new _modelAttribute.default("display_on_index_header", {
          defaultValue: false,
          flag: true
        }), new _modelAttribute.default("display_on_card", {
          defaultValue: false,
          flag: true
        })]);
      }
      return this.#attributes;
    }
    static fieldTypes() {
      if (!this.#fieldTypes) {
        this.#fieldTypes = Object.freeze([new FieldType({
          id: "text"
        }), new FieldType({
          id: "confirm"
        }), new FieldType({
          id: "dropdown",
          hasOptions: true
        }), new FieldType({
          id: "multiselect",
          hasOptions: true
        })]);
      }
      return this.#fieldTypes;
    }
    static fieldTypeById(id) {
      return this.fieldTypes().findBy("id", id);
    }
  }
  _exports.default = CircleCustomField;
  class FieldType {
    static #_ = (() => dt7948.g(this.prototype, "id", [_tracking.tracked]))();
    #id = (() => (dt7948.i(this, "id"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "hasOptions", [_tracking.tracked]))();
    #hasOptions = (() => (dt7948.i(this, "hasOptions"), void 0))();
    constructor(props) {
      this.id = props.id;
      this.hasOptions = props.hasOptions;
    }
    get name() {
      return (0, _discourseI18n.i18n)(`discourse_circles.admin.custom_fields.types.${this.id}`);
    }
  }
});