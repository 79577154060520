define("discourse/plugins/discourse-circles/discourse/components/circles/search-fields/confirm", ["exports", "@ember/component", "@ember/modifier", "discourse/lib/decorators", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/base", "discourse/plugins/discourse-circles/discourse/components/circles/search-fields/wire-frame", "@ember/template-factory"], function (_exports, _component, _modifier, _decorators, _base, _wireFrame, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchFieldConfirm extends _base.default {
    onValueChanged(event) {
      const value = event.target.checked;
      this.args.controller.set(this.args.filter.queryParameter, value ? value : null);
    }
    static #_ = (() => dt7948.n(this.prototype, "onValueChanged", [_decorators.bind]))();
    static #_2 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <SearchFieldWireFrame
          ...attributes
          @controller={{@controller}}
          @filter={{@filter}}
          @value={{@value}}
        >
          <label class="checkbox-label">
            <Input
              name={{@filter.name}}
              @checked={{readonly @value}}
              @type="checkbox"
              {{on "input" this.onValueChanged}}
            />
            <span>
              <span>
                {{this.placeholder}}
              </span>
            </span>
          </label>
        </SearchFieldWireFrame>
      
    */
    {
      "id": "FA8O3q+F",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[17,1]],[[\"@controller\",\"@filter\",\"@value\"],[[30,2],[30,3],[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[8,[32,1],[[16,3,[30,3,[\"name\"]]],[4,[32,2],[\"input\",[30,0,[\"onValueChanged\"]]],null]],[[\"@checked\",\"@type\"],[[28,[31,0],[[30,4]],null],\"checkbox\"]],null],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[10,1],[12],[1,\"\\n            \"],[1,[30,0,[\"placeholder\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"&attrs\",\"@controller\",\"@filter\",\"@value\"],false,[\"readonly\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/search-fields/confirm.js",
      "scope": () => [_wireFrame.default, _component.Input, _modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SearchFieldConfirm;
});