define("discourse/plugins/discourse-circles/discourse/templates/circles/new", ["exports", "@glimmer/component", "ember-route-template", "discourse/helpers/body-class", "discourse-i18n", "discourse/plugins/discourse-circles/discourse/components/circles/form-profile-fields", "@ember/component", "@ember/template-factory"], function (_exports, _component, _emberRouteTemplate, _bodyClass, _discourseI18n, _formProfileFields, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)(class CirclesNew extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          {{bodyClass "circles-new-page"}}
    
          <section>
            <h1>{{i18n "discourse_circles.form.new.title"}}</h1>
            <hr />
            <CirclesFormProfileFields
              @model={{@model}}
              @onAfterSave={{@controller.onAfterSave}}
              @saveBtnText="discourse_circles.form.new.create"
            />
          </section>
        
    */
    {
      "id": "/n1RXi0z",
      "block": "[[[1,\"\\n      \"],[1,[28,[32,0],[\"circles-new-page\"],null]],[1,\"\\n\\n      \"],[10,\"section\"],[12],[1,\"\\n        \"],[10,\"h1\"],[12],[1,[28,[32,1],[\"discourse_circles.form.new.title\"],null]],[13],[1,\"\\n        \"],[10,\"hr\"],[12],[13],[1,\"\\n        \"],[8,[32,2],null,[[\"@model\",\"@onAfterSave\",\"@saveBtnText\"],[[30,1],[30,2,[\"onAfterSave\"]],\"discourse_circles.form.new.create\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[\"@model\",\"@controller\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/templates/circles/new.js",
      "scope": () => [_bodyClass.default, _discourseI18n.i18n, _formProfileFields.default],
      "isStrictMode": true
    }), this))();
  });
});