define("discourse/plugins/discourse-circles/discourse/routes/circle-requests", ["exports", "discourse/routes/group-requests", "discourse-i18n"], function (_exports, _groupRequests, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleRequestsRoute extends _groupRequests.default {
    titleToken() {
      return (0, _discourseI18n.i18n)("discourse_circles.circle.requests.title_token", {
        circle_name: this.modelFor("circle").name
      });
    }
    model(params) {
      this._params = params; // inherited from GroupRequestsRoute

      return this.modelFor("circle").member_group;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.circleModel = this.modelFor("circle");
    }
  }
  _exports.default = CircleRequestsRoute;
});