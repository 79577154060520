define("discourse/plugins/discourse-circles/discourse/components/circles/user-dropdown", ["exports", "@ember-decorators/component", "select-kit/components/combo-box", "select-kit/components/select-kit"], function (_exports, _component, _comboBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserDropdown = dt7948.c(class UserDropdown extends _comboBox.default {
    valueProperty = "username";
    labelProperty = "username";
    modifyComponentForRow() {
      return "user-chooser/user-row";
    }
  }, [(0, _component.classNames)("circles-user-dropdown"), (0, _selectKit.selectKitOptions)({
    caretDownIcon: "caret-right",
    caretUpIcon: "caret-down"
  }), (0, _selectKit.pluginApiIdentifiers)("circles-user-dropdown")]);
  var _default = _exports.default = UserDropdown;
});