define("discourse/plugins/discourse-circles/discourse/components/circles/user-nav-circles-item", ["exports", "@glimmer/component", "@ember/routing", "discourse/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserCircleNavItem extends _component.default {
    static shouldRender(args, context, owner) {
      return owner.lookup("service:circles")?.userCanUseCircles;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li class="user-nav__circles">
          <LinkTo @route="user.circles">
            {{dIcon "discourse_circles"}}
            <span>{{i18n "discourse_circles.user.circles"}}</span>
          </LinkTo>
        </li>
      
    */
    {
      "id": "ZzHnYJOt",
      "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,0,\"user-nav__circles\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@route\"],[\"user.circles\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"discourse_circles\"],null]],[1,\"\\n        \"],[10,1],[12],[1,[28,[32,2],[\"discourse_circles.user.circles\"],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/user-nav-circles-item.js",
      "scope": () => [_routing.LinkTo, _dIcon.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UserCircleNavItem;
});