define("discourse/plugins/discourse-circles/discourse/components/circles/user-notification-preferences", ["exports", "@glimmer/component", "@glimmer/tracking", "discourse/components/preference-checkbox", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _preferenceCheckbox, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CirclesUserNotificationPreferences extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "show", [_tracking.tracked], function () {
      return false;
    }))();
    #show = (() => (dt7948.i(this, "show"), void 0))();
    constructor() {
      super(...arguments);
      this.show = Object.keys(this.args.outletArgs.model.user_option).includes("notify_on_circle_mention");
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.show}}
          <div class="control-group circles-notifications">
            <label class="control-label">
              {{i18n "discourse_circles.user.circles"}}
            </label>
    
            <div class="controls notify-on-circle-mention">
              <PreferenceCheckbox
                @labelKey="discourse_circles.user.notification_preferences.notify_on_circle_mention"
                @checked={{@outletArgs.model.user_option.notify_on_circle_mention}}
              />
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "LRWgQFwh",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"show\"]],[[[1,\"      \"],[10,0],[14,0,\"control-group circles-notifications\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"discourse_circles.user.circles\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls notify-on-circle-mention\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@labelKey\",\"@checked\"],[\"discourse_circles.user.notification_preferences.notify_on_circle_mention\",[30,1,[\"model\",\"user_option\",\"notify_on_circle_mention\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/circles/user-notification-preferences.js",
      "scope": () => [_discourseI18n.i18n, _preferenceCheckbox.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CirclesUserNotificationPreferences;
});