define("discourse/plugins/discourse-circles/discourse/components/modal/add-members", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/helper", "@ember/object", "@ember/service", "@ember/utils", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax-error", "discourse/lib/utilities", "discourse-i18n", "select-kit/components/user-chooser", "truth-helpers/helpers/not", "truth-helpers/helpers/or", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _helper, _object, _service, _utils, _dButton, _dModal, _ajaxError, _utilities, _discourseI18n, _userChooser, _not, _or, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleAddMembersController extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "setOwner", [_tracking.tracked], function () {
      return false;
    }))();
    #setOwner = (() => (dt7948.i(this, "setOwner"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "notifyUsers", [_tracking.tracked], function () {
      return true;
    }))();
    #notifyUsers = (() => (dt7948.i(this, "notifyUsers"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "usernamesAndEmails", [_tracking.tracked], function () {
      return [];
    }))();
    #usernamesAndEmails = (() => (dt7948.i(this, "usernamesAndEmails"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "flash", [_tracking.tracked]))();
    #flash = (() => (dt7948.i(this, "flash"), void 0))();
    get circle() {
      return this.args.model.circle;
    }
    get mode() {
      return this.args.model.mode;
    }
    get group() {
      return this.args.model.group;
    }
    get groupsAllowed() {
      return this.siteSettings.discourse_circles_groups_allowed_to_use_circles?.split("|");
    }
    get rawTitle() {
      return (0, _discourseI18n.i18n)(`discourse_circles.circle.add_members.title.${this.mode}`, {
        circle_name: this.args.model.circle.name
      });
    }
    get description() {
      return (0, _discourseI18n.i18n)(`discourse_circles.circle.add_members.description.${this.mode}`, {
        circle_name: this.args.model.circle.name
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "description", [(0, _object.computed)("mode")]))();
    get usernames() {
      return this.usernamesAndEmails.reject(_utilities.emailValid).join(",");
    }
    setUsernamesAndEmails(usernamesAndEmails) {
      this.usernamesAndEmails = usernamesAndEmails;
      if (this.emails) {
        if (!this.usernames) {
          this.notifyUsers = false;
        }
        this.setOwner = false;
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "setUsernamesAndEmails", [_object.action]))();
    async addMembers() {
      if ((0, _utils.isEmpty)(this.usernamesAndEmails)) {
        return;
      }
      this.loading = true;
      try {
        await this.circle.addMembers(this.usernames, this.mode === "staff", this.notifyUsers);
        this.args.model.reloadMembers?.();
        this.router.transitionTo(`circle.${this.mode}`, this.circle.slug_path_with_id, {
          queryParams: {}
        });
        this.args.closeModal();
      } catch (error) {
        this.flash = (0, _ajaxError.extractError)(error);
      } finally {
        this.loading = false;
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "addMembers", [_object.action]))();
    static #_12 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          class="circles-add-members-modal"
          @title={{this.rawTitle}}
          @closeModal={{@closeModal}}
          @flash={{this.flash}}
          @flashType="error"
        >
          <:body>
    
            <form class="form-vertical circles-add-members">
              <p>{{this.description}}</p>
    
              <div class="input-group">
                <UserChooser
                  @value={{this.usernamesAndEmails}}
                  @onChange={{this.setUsernamesAndEmails}}
                  @options={{hash
                    allowEmails=false
                    filterPlaceholder="discourse_circles.circle.add_members.usernames_placeholder"
                    customSearchOptions=(hash groupIds=this.groupsAllowed)
                  }}
                />
                <div class="control-instructions">
                  {{i18n
                    "discourse_circles.circle.add_members.only_allowed_disclaimer"
                  }}
                </div>
              </div>
    
              {{#if this.currentUser.staff}}
                <div class="input-group circles-notify-users">
                  <label>
                    <Input @type="checkbox" @checked={{this.notifyUsers}} />
                    {{i18n "discourse_circles.circle.add_members.notify_users"}}
                  </label>
                </div>
              {{/if}}
            </form>
          </:body>
    
          <:footer>
            <DButton
              class="circles-add-members-button btn-primary"
              @action={{this.addMembers}}
              @icon="plus"
              @disabled={{or this.loading (not this.usernamesAndEmails)}}
              @label="groups.add"
            />
          </:footer>
        </DModal>
      
    */
    {
      "id": "oI06sUWu",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"circles-add-members-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\",\"@flashType\"],[[30,0,[\"rawTitle\"]],[30,1],[30,0,[\"flash\"]],\"error\"]],[[\"body\",\"footer\"],[[[[1,\"\\n\\n        \"],[10,\"form\"],[14,0,\"form-vertical circles-add-members\"],[12],[1,\"\\n          \"],[10,2],[12],[1,[30,0,[\"description\"]]],[13],[1,\"\\n\\n          \"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@value\",\"@onChange\",\"@options\"],[[30,0,[\"usernamesAndEmails\"]],[30,0,[\"setUsernamesAndEmails\"]],[28,[32,2],null,[[\"allowEmails\",\"filterPlaceholder\",\"customSearchOptions\"],[false,\"discourse_circles.circle.add_members.usernames_placeholder\",[28,[32,2],null,[[\"groupIds\"],[[30,0,[\"groupsAllowed\"]]]]]]]]]],null],[1,\"\\n            \"],[10,0],[14,0,\"control-instructions\"],[12],[1,\"\\n              \"],[1,[28,[32,3],[\"discourse_circles.circle.add_members.only_allowed_disclaimer\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"currentUser\",\"staff\"]],[[[1,\"            \"],[10,0],[14,0,\"input-group circles-notify-users\"],[12],[1,\"\\n              \"],[10,\"label\"],[12],[1,\"\\n                \"],[8,[32,4],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"notifyUsers\"]]]],null],[1,\"\\n                \"],[1,[28,[32,3],[\"discourse_circles.circle.add_members.notify_users\"],null]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,5],[[24,0,\"circles-add-members-button btn-primary\"]],[[\"@action\",\"@icon\",\"@disabled\",\"@label\"],[[30,0,[\"addMembers\"]],\"plus\",[28,[32,6],[[30,0,[\"loading\"]],[28,[32,7],[[30,0,[\"usernamesAndEmails\"]]],null]],null],\"groups.add\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/components/modal/add-members.js",
      "scope": () => [_dModal.default, _userChooser.default, _helper.hash, _discourseI18n.i18n, _component2.Input, _dButton.default, _or.default, _not.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CircleAddMembersController;
});