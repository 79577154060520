define("discourse/plugins/discourse-circles/discourse/routes/circle-manage", ["exports", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CircleManageRoute extends _discourse.default {
    showFooter = true;
    titleToken() {
      return (0, _discourseI18n.i18n)("discourse_circles.circle.manage.title_token", {
        circle_name: this.modelFor("circle").name
      });
    }
    model() {
      return this.modelFor("circle");
    }
    serialize(model) {
      return {
        name: model.get("name").toLowerCase()
      };
    }

    // afterModel(circle) {
    // if (
    //   !this.currentUser ||
    //   (!circle.can_admin_group &&
    //     !this.currentUser.canManageGroup(group))
    // ) {
    // TODO redirect to another page if the user can't admin the circle
    // }
    // }
  }
  _exports.default = CircleManageRoute;
});