define("discourse/plugins/discourse-circles/discourse/templates/admin/plugins/circles/custom-fields", ["exports", "@glimmer/component", "ember-route-template", "discourse/components/d-button", "discourse-i18n", "discourse/plugins/discourse-circles/discourse/components/admin/custom-field-item", "@ember/component", "@ember/template-factory"], function (_exports, _component, _emberRouteTemplate, _dButton, _discourseI18n, _customFieldItem, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberRouteTemplate.default)(class AdminPluginsCirclesCustomFields extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          <div class="circles-custom-fields">
            <h2>{{i18n "discourse_circles.admin.custom_fields.title"}}</h2>
            <p class="desc">
              {{i18n "discourse_circles.admin.custom_fields.help"}}
            </p>
    
            {{#if @model}}
              <ol class="circles-custom-fields__list">
                {{#each @controller.sortedFields as |field|}}
                  <AdminCustomFieldItem
                    @model={{field}}
                    @fieldTypes={{@controller.fieldTypes}}
                    @firstField={{@controller.firstField}}
                    @lastField={{@controller.lastField}}
                    @destroyAction={{@controller.destroyField}}
                    @moveUpAction={{@controller.moveUp}}
                    @moveDownAction={{@controller.moveDown}}
                  />
                {{/each}}
              </ol>
            {{/if}}
    
            <DButton
              class="btn-primary circles-custom-fields__new-field-btn"
              @disabled={{@controller.createDisabled}}
              @action={{@controller.createField}}
              @label="discourse_circles.admin.custom_fields.create"
              @icon="plus"
            />
          </div>
        
    */
    {
      "id": "IPz4Jx+a",
      "block": "[[[1,\"\\n      \"],[10,0],[14,0,\"circles-custom-fields\"],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[1,[28,[32,0],[\"discourse_circles.admin.custom_fields.title\"],null]],[13],[1,\"\\n        \"],[10,2],[14,0,\"desc\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"discourse_circles.admin.custom_fields.help\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,1],[[[1,\"          \"],[10,\"ol\"],[14,0,\"circles-custom-fields__list\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,2,[\"sortedFields\"]]],null]],null],null,[[[1,\"              \"],[8,[32,1],null,[[\"@model\",\"@fieldTypes\",\"@firstField\",\"@lastField\",\"@destroyAction\",\"@moveUpAction\",\"@moveDownAction\"],[[30,3],[30,2,[\"fieldTypes\"]],[30,2,[\"firstField\"]],[30,2,[\"lastField\"]],[30,2,[\"destroyField\"]],[30,2,[\"moveUp\"]],[30,2,[\"moveDown\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[32,2],[[24,0,\"btn-primary circles-custom-fields__new-field-btn\"]],[[\"@disabled\",\"@action\",\"@label\",\"@icon\"],[[30,2,[\"createDisabled\"]],[30,2,[\"createField\"]],\"discourse_circles.admin.custom_fields.create\",\"plus\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[\"@model\",\"@controller\",\"field\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-circles/discourse/templates/admin/plugins/circles/custom-fields.js",
      "scope": () => [_discourseI18n.i18n, _customFieldItem.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  });
});